import React from "react";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="brand" className="about-us ptb-100 position-relative ">
          <div className="container">
            <div className="row align-items-center justify-content-lg-between justify-content-md-center">
              <div className="col-md-5 col-lg-4">
                <div className="about-content-right">
                  <img src="\assets\Skype_Picture_2024_01_22T12_25_04_251Z.jpeg" alt="about us" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-12 col-lg-7">
                <div className="about-content-left section-heading">
                  <h2> What's in it for you? </h2>
                  <ul color="#141615" className="check-list-wrap list-two-col py-3">
                    <li style={{color:"#141615"}}>Create loyalty </li>
                    <li  style={{color:"#141615"}}>Expand visibility and influence  </li>
                    <li  style={{color:"#141615"}}>Engage with consumers </li>
                    <li  style={{color:"#141615"}}> Deliver relevant deals   </li>
                    <li  style={{color:"#141615"}}> Gain new customers   </li>
                    <li  style={{color:"#141615"}}> Introduce new products with comprehensive surveys and testing</li>
                  </ul>
                  <div className="action-btns mt-4">
                    <a href="#loyalty" className="btn btn-brand-02 mr-3 page-scroll">Learn more</a>
                    {/* <a href="/#" className="btn btn-outline-brand-02">Learn More</a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default About;
