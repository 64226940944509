import React, { Component } from "react";
import Download from "../components/CallToAction/Download";

import Contact from "../components/Contact";
import Blog from "../components/Blog";
import TrustedCompany from "../components/TrustedCompany/TrustedCompany";
import Footer from "../components/Footer";
import About from "../components/AboutUs/AboutUs3";
import About1 from "../components/AboutUs/AboutUsSection4";
import HeaderForSaver from "../components/Header/HeaderForSaver";
import Hero from "../components/HeroSection/HeroSection5";
import DownloadForSaver from "../components/CallToAction/DownloadForSaver";
import PromoSaver from "../components/PromoSection/PromoSaver";
import WorkProcess from "../components/WorkProcess";
// import Download from "../components/CallToAction/DownloadExt";


class Theme extends Component {
  render() {
    return (
      <React.Fragment>
        <HeaderForSaver/>
        <div className="main">
          <Hero />
          <PromoSaver />
          <WorkProcess removeTop={false} />
          <DownloadForSaver/>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Theme;
