import React from "react";
import { connect } from "react-redux";
import { subscribe } from "../../actions/index";

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleFormValueChange(inputName, event) {
        let stateValue = {};
        stateValue[inputName] =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        this.setState(stateValue);
    }

    handleSubmit(event) {
        event.preventDefault();
        // get action
        const subscribeAction = subscribe(this.state);

        // Dispatch the contact from data
        this.props.dispatch(subscribeAction);

        // added delay to change button text to previous
        setTimeout(
            function () {
                // get action again to update state
                const subscribeAction = subscribe(this.state);

                // Dispatch the contact from data
                this.props.dispatch(subscribeAction);

                // clear form data
                this.setState({
                    email: ""
                });
            }.bind(this),
            3000
        );
    }

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */
    }

    render() {
        return (
            <React.Fragment>
                <div className="footer-bottom py-3 ">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-lg-7">
                                <div className="copyright-wrap small-text">
                                    <p style={{color:'rgb(20,21,22'}} className="mb-0 ">&copy; ShopKya. All rights reserved. Powered by Sabh Nine Technologies.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-5">
                                <div className="terms-policy-wrap text-lg-right text-md-right text-left">
                                    <ul className="list-inline text-white">
                                        <li className="list-inline-item "><a style={{ textDecoration: 'underline',color:'rgb(20,21,22' }} className="small-text " href="/terms">Terms of Use</a></li>
                                        <li className="list-inline-item"><a style={{ textDecoration: 'underline', color:'rgb(20,21,22' }} className="small-text " href="/privacy-policy">Privacy Policy </a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(state => ({
    state
}))(Footer);
