import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// importing all the themes
import Theme1 from "../themes/theme1";
import ThemeSaver from "../themes/themeSaver";
import NotFound from "../themes/404";
import Terms from "../themes/terms";
import Privacy from "../themes/Privacy";
import SPrivacy from "../themes/SaverPrivacy";
import STerms from "../themes/SaverTerms";
export default class Routes extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/privacy-policy" component={Privacy} />
            <Route exact path="/mobile-terms" component={STerms} />
            <Route exact path="/mobile-privacy-policy" component={SPrivacy} />
            <Route exact path="/404" component={NotFound} />
            <Route exact path="/partners" component={Theme1} />
            <Route path="/" component={ThemeSaver} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}
