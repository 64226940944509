import React from "react";
import { connect } from "react-redux";
import { subscribe } from "../../actions/index";
import _data from "../../data";
import { TextAnime } from "./TextAnime";

class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hero: {},
      email: "",
      disableBtn: false,
      btnText: "Subscribe"
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  changeBtnText = btnText => {
    this.setState({ btnText });
  };

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  handleSubmit(event) {
    event.preventDefault();

    // disable the button
    this.setState({ disableBtn: true });

    // get action
    const subscribeAction = subscribe(this.state);

    // Dispatch the contact from data
    this.props.dispatch(subscribeAction);

    // added delay to change button text to previous
    setTimeout(
      function () {
        // enable the button
        this.setState({ disableBtn: false });

        // change to button name
        this.changeBtnText("Subscribe");

        // get action again to update state
        const subscribeAction = subscribe(this.state);

        // Dispatch the contact from data
        this.props.dispatch(subscribeAction);

        // clear form data
        this.setState({
          email: ""
        });
      }.bind(this),
      3000
    );
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      hero: _data.hero
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="ptb-100 bg-image overflow-hidden" >
          {/* <div className="hero-bottom-shape-two" style={{
            backgroundImage: "url(assets/img/hero-bottom-shape-2.svg)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom center"
          }}></div> */}
          <div className="container">
            <div className="row align-items-center justify-content-lg-between justify-content-md-center justify-content-sm-center">
              <div style={{marginTop:'30px'}} className="col-md-12 col-lg-12 d-flex align-items-center justify-content-center pt-30">
                <div className="hero-slider-content text-white py-5 ">

                  <h1 style={{ display: 'flex', fontSize:'4.0rem' }} className="text-black flex text-center">
                    <TextAnime duration={3000} className="font-semibold xxs:text-center" color="#1b2e4b" animation="rotate" data={["Quickest", "Smartest", "Simplest"]} />
                    <span> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; platform to <br/> grow your business </span> </h1>
                  {/* <p style={{color:'#000'}} className="lead text-black">Holisticly procrastinate mission-critical convergence with reliable customer service. Assertively underwhelm idea-sharing for impactful solutions.</p> */}

                  <div className="action-btns mt-4">
                    <ul className="list-inline d-flex align-items-center justify-content-center">
                      <li className="list-inline-item">
                        <a style={{ backgroundColor: 'rgb(163, 44, 222)', borderColor: "rgb(163, 44, 222)" }} href="/#" className="d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded">
                          <span style={{ color: 'white' }} className="fab fa-apple icon-size-sm mr-3"></span>
                          <div style={{ color: 'white' }} className="download-text text-left">
                            <small>Coming soon</small>
                            <h5 className="mb-0">App Store</h5>
                          </div>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a style={{ backgroundColor: 'rgb(163, 44, 222)', borderColor: "#A32CDE" }} href="/#" className="d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded">
                          <span style={{ color: 'white' }} className="fab fa-google-play icon-size-sm mr-3"></span>
                          <div style={{ color: 'white' }} className="download-text text-left">
                            <small>Coming soon</small>
                            <h5 className="mb-0">Google Play</h5>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6 col-sm-6 col-lg-5">
                <div className="img-wrap">
                  <img src="assets/img/app-mobile-image.png" alt="app" className="img-fluid" />
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(HeroSection);
