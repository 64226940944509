import React, { Component } from "react";

import HeaderForSaver from "../components/Header/HeaderForSaver";
import Footer from "../components/Footer2";
import SPrivacyText from "../components/Legal/SaverPrivacy";

class Theme extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="main">
          <SPrivacyText/>
        </div>
      </React.Fragment>
    );
  }
}

export default Theme;
