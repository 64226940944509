import React from "react";
import { connect } from "react-redux";
import './index.css'
class Header extends React.Component {
  render() {
    return (
      <React.Fragment>
        <header style={{ backgroundColor: '#f4f5f6' }} className="header">
          <div className="container">
            <div className="row justify-content-start py-1 marginLeft">
              <nav>
                <ul className="uiList">
                  <li className={`liList ${window.location.pathname == '/' ? ' selected' : ''}`} ><a  href='/' className="ankerLink" >Savers</a></li>
                  <li className={`liList ${window.location.pathname == '/partners' ? ' selected' : ''}`}><a target="_blank" style={{ color: "#471362" }} className="ankerLink" href="/partners">Partners</a></li>
                </ul>
              </nav>
            </div>
          </div>
          <nav style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }} className={`navbar affix1 navbar-expand-lg ${this.props.terms ? 'fixed-top-legal' : 'fixed-top'} ${!this.props.terms ? 'bg-transparent' : 'gradient-bg'}`}>
            <div className="container">
              <a className="navbar-brand" href="/partners">
                <img height={22} src={this.props.isColorLogo && this.props.isColorLogo === true ? "/assets/Saverlogo.png" : "/assets/Saverlogo.png"}
                  alt="logo" />
              </a>
              {!this.props.terms && <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span style={{ color: 'black' }} className="ti-menu"></span>
              </button>}

              {!this.props.terms ? <div className="collapse navbar-collapse h-auto" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto menu">
                  <li>
                    <a style={{ opacity: '1', color: 'black', fontWeight: '500' }} href="/partners" className="page-scroll"> Home</a>
                  </li>
                  <li><a style={{ opacity: '1', color: 'black', fontWeight: '500' }} href="#about" className="page-scroll">ShopKya</a></li>
                  <li><a style={{ opacity: '1', color: 'black', fontWeight: '500' }} href="#brand" className="page-scroll">Brand Solutions</a></li>
                  <li><a style={{ opacity: '1', color: 'black', fontWeight: '500' }} href="#contact" className="page-scroll">Contact</a></li>
                </ul>
              </div> : <div className="p-4">
                <a href="/">
                  <i style={{ color: '#A32CDE' }} class="fas fa-home " />
                </a>
              </div>}
            </div>
          </nav>
        </header>
      </React.Fragment>
    );
  }
}

export default connect(state => ({}))(Header);
