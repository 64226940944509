import React, { Component } from "react";

import HeaderForSaver from "../components/Header/HeaderForSaver";
import Footer from "../components/Footer2";
import STermsText from "../components/Legal/SaverTerms";

class Theme extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="main">
          <STermsText/>
        </div>
      </React.Fragment>
    );
  }
}

export default Theme;
