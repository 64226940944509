import React from "react";
import { connect } from "react-redux";
import _data from "../../data";

class PromoSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            promo: {}
        };
    }

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */

        this.setState({
            promo: _data.promo
        });
    }

    render() {
        return (
            <React.Fragment>
                <section id="about" className="promo-section ptb-100 gray-light-bg">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-9">
                                <div className="section-heading text-center">
                                    <h2 className="mb-5">ShopKya-kya?</h2>
                                    <p style={{ fontSize: '18px', textAlign: 'left', color: '#141615' }} className="text-[18px] text-[#141615]">
                                        At ShopKya, we're on a mission to build brand loyalty from the ground up. Our expertise spans strategy, streamlined processes, data insights, and technology-driven engagement. We focus on achieving key objectives like customer acquisition, retention, fostering loyalty, and driving overall business growth and excellence.
                                        <br />  <br /> Our vision, combined with the intelligence of our team and a tailored tech ecosystem, is the driving force behind our success.
                                        <br /> <br />We specialize in crafting personalized loyalty programs that help you connect, retain, and reward customers. Whether in good times or tough situations, we assist you in boosting sales and increasing market share by establishing connections with your customers across the entire value chain.
                                        <br />  <br />
                                        Operating seamlessly in both B2B and B2C models, ShopKya is your partner for navigating the intricacies of loyalty in the business world.

                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row justify-content-md-center justify-content-sm-center">
                            <div className="col-md-6 col-lg-4">
                                <div className="card border-0 single-promo-card single-promo-hover text-center p-2 mt-4">
                                    <div className="card-body">
                                        <div className="pb-2">
                                            <span className="fas fa-cubes icon-size-lg color-secondary"></span>
                                        </div>
                                        <div className="pt-2 pb-3">
                                            <h5>Fully functional</h5>
                                            <p className="mb-0">Phosfluorescently target bleeding sources through viral methodsp progressively expedite empowered.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="card border-0 single-promo-card single-promo-hover text-center p-2 mt-4">
                                    <div className="card-body">
                                        <div className="pb-2">
                                            <span className="fas fa-headset icon-size-lg color-secondary"></span>
                                        </div>
                                        <div className="pt-2 pb-3">
                                            <h5>24/7 Live Chat</h5>
                                            <p className="mb-0">Enthusiastically productivate interactive interfaces after energistically scale client-centered catalysts.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="card border-0 single-promo-card single-promo-hover text-center p-2 mt-4">
                                    <div className="card-body">
                                        <div className="pb-2">
                                            <span className="fas fa-lock icon-size-lg color-secondary"></span>
                                        </div>
                                        <div className="pt-2 pb-3">
                                            <h5>Secure Data</h5>
                                            <p className="mb-0">Synergistically architect virtual content solutions. Monotonectally communicate cooperative solutions.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default connect(state => ({
    state
}))(PromoSection);
