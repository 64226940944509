import React, { Component } from "react";

import Header from "../components/Header/header";
import Hero from "../components/HeroSection/HeroSection1";
import Download from "../components/CallToAction/Download";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Promo2 from "../components/PromoSection/Promo2";
import About from "../components/AboutUs/AboutUs3";
import About1 from "../components/AboutUs/AboutUsSection4";
// import Download from "../components/CallToAction/DownloadExt";


class Theme extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="main">
          <Hero />
          <Promo2 />
          <About />
          {/* <Promo /> */}
          {/* <Testimonial /> */}
          {/* <Faq /> */}
          <About1 />
          <Contact />
          {/* <TrustedCompany /> */}
          <Download/>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Theme;
