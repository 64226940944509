import React from "react";

class PrivacyText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */
    }

    render() {
        return (
            <React.Fragment>
                <section className="ptb-100 gray-light-bg pt-100 mt-100">
                    <div className="container pt-88">
                        <div className="row justify-content-start">
                            <div className="col-md-12 col-lg-8">
                                <div className="section-heading mb-3 text-start">
                                    <h2 style={{marginBottom:'0px'}}>Privacy Policy</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    {/* <h5>1. Introduction</h5> */}
                                    <p className="text-right">Effective Date:  April 1, 2024</p>
                                    <p>
                                        Thank you for choosing to use ‘ShopKya’ (“ShopKya” or “App), provided by Sabh Nine Technologies (“we”, “us,” or “our”). We are committed to protecting your privacy and ensuring the security of your personal information.
                                        In this Privacy Policy we will refer to users as “users”, “you”, or “your”. This Privacy Policy outlines how we collect, store, use, disclose, and safeguard your information when you use our mobile application.
                                        We take your privacy seriously. If you have any questions about this Policy or about privacy at ShopKya, please contact us at
                                        <a href="mailto:bhavya@shopkya.com"> bhavya@shopkya.com</a>. Please take the time
                                        to read this Privacy Policy carefully. By accessing or using the App, you agree to the terms of this Privacy Policy.

                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>1. Collection of Information</h5>
                                    <div style={{ marginLeft: '25px' }}>
                                        <h6>1.1. Information You Provide: </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            <p>
                                                We may collect the information that you voluntarily provide to us when you use the App, such as:
                                            </p>
                                            <p>(A) Personal Information:</p>
                                            <div style={{ marginLeft: '25px' }}>
                                                (i) Full name <br />
                                                (ii) Age<br />
                                                (iii) Date of Birth<br />
                                                (iv) Gender<br />
                                                (v) Email address<br />
                                                (vi) Phone number<br />
                                                (vii) Delivery address<br />
                                                (viii) Profile picture
                                            </div>
                                            <br />
                                            <p>(B) Sensitive Personal Information:</p>
                                            <div style={{ marginLeft: '25px' }}>
                                                (i) Passwords<br />
                                                (ii) Payment information (credit/ debit card details, wallets, bank account information, other payment instrument details, etc.)
                                                <br /> (iii) Any receipt, bill, invoice, memo, challan, etc. uploaded by you on our App
                                                <br /> (iv) Any other information you choose to provide in forms or through interactions with the App
                                            </div><br />
                                            <p>(C) Transactional Information:</p>
                                            <div style={{ marginLeft: '25px' }}>
                                                If you decide to use the App, we will gather and retain information regarding your transactions including, but not limited to, data uploaded,
                                                contents of the receipts/ invoice/ memo uploaded, transaction status, order history, frequency of transactions, details of your interactions (such as customer support inquiries), preferences on the App and any other data that you may provide.
                                                {/* If you decide to use the App, we will gather and retain information regarding your transactions. This includes transaction status,
                                                order history, frequency of transactions, and details of your interactions (such as customer support inquiries) and preferences on the App. */}
                                            </div>
                                        </div>
                                        <br />
                                        <h6>1.2. Automatically Collected Information:  </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            When you use the App, we may automatically collect certain information about your device and usage patterns, including:
                                            <br /><br />
                                            (i) Device type and model<br />
                                            (ii) Operating system<br />
                                            (iii) Unique device identifiers (such as IMEI or UDID)<br />
                                            (iv) IP address<br />
                                            (v) Mobile network information<br />
                                            (vi) Mobile device software development kits<br />
                                            (vii) Server log files<br />
                                            (viii) Cookie IDs<br />
                                            (ix) App usage data (such as time spent on the App, pages visited, features used, etc.)<br /><br />

                                            This information helps us improve the functionality and user experience of the App.
                                        </div>
                                        <br />
                                        <h6>1.3. Location Information:  </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            With your explicit consent, we may collect information about your precise or approximate location using GPS, Wi-Fi, or other technologies. We use this information to provide location-based services, such as:
                                            <br /><br />
                                            (i) Delivering goods from nearby grocery shops<br />
                                            (ii) Locating nearby services or promotions relevant to your location<br /><br />

                                            You can enable or disable location services through your device settings or within the App. However, disabling location services may limit certain features of the App.

                                        </div>
                                        <br />
                                        <h6>1.4. Information from Third Parties:   </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            We may receive information about you from third parties, such as:
                                            <br /><br />
                                            (i) Payment processors<br />
                                            (ii) Delivery partners<br />
                                            (iii) Other service providers<br /><br />

                                            This information may be used to fulfill orders, provide services, or enhance your experience with the App.

                                        </div>
                                    </div>
                                    <br />
                                    <h5>
                                        2. Use of Information
                                    </h5>
                                    <div style={{ marginLeft: '25px' }}>
                                        <h6>2.1. Provide and Improve Services: </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            We use the information we collect to provide, maintain, and improve the services offered through the App, including:
                                            <br /><br />
                                            (i) Providing, maintaining and improving our services;<br />
                                            (ii) Providing rewards;<br />
                                            (iii) Processing orders;<br />
                                            <br /> (iv) Resolving disputes, administer our service and diagnose/ troubleshoot technical problems;
                                            <br /> (v) Designing and improving the products and services and customer relationship management processes;
                                            <br /> (vi) Collecting money from you in relation to the services offered;
                                            <br /> (vii) Informing you about online and offline offers, products, services, and updates;
                                            <br />(viii) Customizing your experience on the App or share marketing material with you;
                                            <br />(ix) Tracking and implementing your Privacy preferences and settings;
                                            <br /> (x) Authenticating your account, and detecting, preventing and protecting us from any errors, fraud and other criminal or prohibited activity on the App;
                                            <br /> (xi) Processing and fulfilling your request or responding to your comments, and queries on the App;
                                            <br />  (xii) Allowing our service providers, business partners, affiliated stores and/or associates to present customized messages to you;
                                            <br />  (xiii) To carry out our obligations and enforce our rights arising from any contract entered into between you and us; and
                                            <br /> (xiv) To enhance the user experience.
                                            <br /><br />
                                            We may analyze usage patterns and feedback to identify areas for improvement and develop new features or services.

                                        </div>
                                        <br />
                                        <h6>2.2. Communication: </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            We may use your contact information to communicate with you about:
                                            <br /><br />
                                            (i) Your orders<br />
                                            (ii) Account updates<br />
                                            (iii) Promotional offers<br />
                                            (iv) Other relevant information related to the App<br />
                                            <br />
                                            We may communicate with you via email, SMS, push notifications, or other methods as permitted by applicable law.
                                        </div>
                                        <br />
                                        <h6>2.3. Personalization: </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            We may use the information collected to personalize your experience with the App, such as:<br />
                                            <br />
                                            (i) Recommending products or services based on your preferences <br />
                                            (ii) Tailoring content or promotions to your interests<br />
                                            (iii) Customizing the user interface to enhance usability<br />
                                        </div>
                                        <br />
                                        <h6>
                                            2.4. Analytics:
                                        </h6>
                                        <div style={{ marginLeft: '25px' }}>

                                            We use analytics tools to analyze usage trends, monitor the performance of the App, and gather demographic information about our users.
                                            This helps us understand how users interact with the App and make improvements accordingly. The information collected may include:
                                            <br /> <br />
                                            (i) Demographic data (such as age, gender, and location) <br />
                                            (ii) User behavior (such as navigation paths, feature usage, and session duration) <br />
                                            (iii) Device and platform information (such as device type, operating system, and screen resolution) <br /> <br />

                                            We may use third-party analytics providers to assist us in these efforts, and they may collect information on our behalf in accordance with their own privacy policies.
                                        </div>
                                        <br />
                                        <h6>
                                            2.5. Legal Compliance:
                                        </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            We may use or disclose your information if required to do so by law or in response to a summons, court order, or other legal process. We may also use or disclose your information to:
                                            <br /><br />
                                            <br /> (i) Comply with applicable laws, regulations, or legal obligations
                                            <br />  (ii) Protect our rights or property
                                            <br />(iii) Investigate or prevent fraud or illegal activities
                                            <br />(iv) Ensure the safety and security of our users or the public
                                        </div>
                                    </div>
                                    <br />
                                    <h5>
                                        3. Sharing of Information
                                    </h5>
                                    <div style={{ marginLeft: '25px' }}>
                                        <h6>3.1. Service Providers: </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            We may share your information with third-party service providers who assist us in operating the App, processing payments, delivering goods, and performing other business functions. These service providers may include:
                                            <br /><br />

                                            (i) Payment processors<br />
                                            (ii) Delivery partners<br />
                                            (iii) Hosting providers<br />
                                            (iv) Customer support services<br />
                                            (v) Analytics providers<br />
                                            (vi) Marketing and advertising partners<br /><br />

                                            These service providers are contractually obligated to use your information solely to provide services to us and are bound by confidentiality agreements.
                                        </div>
                                        <br />
                                        <h6>3.2. Business Transfers:  </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            In the event of a merger, acquisition, reorganization, or sale of all or a portion of our assets, your information may be transferred as part of the transaction.
                                            We will notify you via email and/ or a prominent notice on the App of any change in ownership or use of your personal information.

                                        </div>
                                        <br />
                                        <h6>3.3. Aggregate Information:   </h6>
                                        <div style={{ marginLeft: '25px' }}>

                                            We may share aggregated and anonymized information with third parties for analytical, research, or marketing purposes. This information does not identify you personally and may be used to:
                                            <br /><br />
                                            (i) Generate statistical reports<br />
                                            (ii) Conduct market research<br />
                                            (iii) Improve our products or services<br />
                                            (iv) Develop new business opportunities<br />
                                        </div>
                                    </div>
                                    <br />
                                    <h5>
                                        4. Data Security
                                    </h5>
                                    <div style={{ marginLeft: '25px' }}>
                                        <h6>4.1. Security Measures: </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            We take reasonable measures to protect the security of your information, both during transmission and once it is received. These measures may include:
                                            <br /><br />
                                            (i) Encryption of sensitive data (such as payment information)
                                            <br />  (ii) Implementation of firewalls and intrusion detection systems
                                            <br />  (iii) Regular security audits and vulnerability assessments
                                            <br />   (iv) Secure Socket Layer (SSL) technology to encrypt data transmissions
                                            <br />  (v) Access controls and authentication mechanisms to restrict access to personal information
                                            <br />  <br />
                                            However, please be aware that no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee the absolute security of your information.
                                        </div>
                                        <br />
                                        <h6>
                                            4.2. Data Retention:
                                        </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            We will retain your information for as long as necessary to fulfill the purposes outlined in this
                                            Privacy Policy, unless a longer retention period is required or permitted by law. When we no longer need your information,
                                            we will securely delete or anonymize it to prevent unauthorized access or use.
                                        </div>
                                    </div>
                                    <br />
                                    <h5>
                                        5. Third-Party Sites
                                    </h5>
                                    <div style={{ marginLeft: '25px' }}>
                                        <p>
                                            5.1. The App may contain links to third-party websites ("Third-Party Sites") that may collect your personal information such as your IP address, browser specifications, or operating system. We are not responsible for the security of such information, nor for the privacy practices or
                                            content of those Third-Party Sites. You may also encounter "cookies" or similar tracking technologies on certain pages of Third-Party Sites. Please note that we do not control the use of cookies by these Third-Party Sites. These third-party service providers and Third-Party Sites may have their own privacy policies governing the storage and retention of your information,
                                            to which you may be subject. This Privacy Policy does not apply to any information provided to, stored on, or used by these third-party providers and Third-Party Sites. We recommend reviewing the privacy policy of any Third-Party Site you visit to understand how your information is safeguarded. Additionally, we may use third-party advertising companies to display ads
                                            when you visit the App. These companies may use information about your visits to the App and
                                            Third-Party Sites to provide advertisements about goods and services that may be of interest to you. You agree and acknowledge that we are not liable
                                            for the information published in search results or by any Third-Party Sites.

                                        </p>

                                        <br />
                                        {/* <h6>
                                            4.2. Data Retention:
                                        </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            We will retain your information for as long as necessary to fulfill the purposes outlined in this
                                            Privacy Policy, unless a longer retention period is required or permitted by law. When we no longer need your information,
                                            we will securely delete or anonymize it to prevent unauthorized access or use.
                                        </div> */}
                                    </div>
                                    <br />
                                    <h5>
                                        6. Your Choices
                                    </h5>
                                    <div style={{ marginLeft: '25px' }}>
                                        <h6>
                                            6.1.  Opt-Out:
                                        </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            You may choose not to provide certain information or opt-out of receiving promotional emails from us by following the instructions provided in the emails. You may also adjust
                                            your notification settings within the App to control the types of communications you receive.
                                        </div>

                                        <br />
                                        <h6>
                                            6.2. Location Services:
                                        </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            You can enable or disable location services through your device settings or within the App. However,
                                            disabling location services may limit certain features of the App that rely on your location.
                                        </div>
                                        <br />
                                        <h6>
                                            6.3.   Correction/Rectification:
                                        </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            You are given the right to correct inaccurate or incomplete personal data in
                                            certain circumstances.
                                        </div>
                                        <br />
                                    </div>
                                    <br />
                                    <h5>
                                        7. Children's Privacy
                                    </h5>
                                    <div style={{ marginLeft: '25px' }}>
                                        <h6>
                                            7.1. Age Restriction:
                                        </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            The App is not intended for use by children under the age of 18. We do not knowingly collect personal
                                            information from children under the age of 18. If you are a parent or guardian and believe that your child has provided us with personal information,
                                            please contact us, and we will delete the information from our systems.
                                        </div>
                                        <br />
                                    </div>
                                    <br />
                                    <h5>
                                        8. Changes to This Privacy Policy
                                    </h5>
                                    <div style={{ marginLeft: '25px' }}>
                                        <h6>
                                            8.1. Updates:
                                        </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            We may update this Privacy Policy from time to time to reflect changes in our practices or legal
                                            requirements. We will notify you of any material changes to this Privacy Policy by posting the
                                            revised policy on the App and updating the "Effective Date" at the top of this Privacy Policy.
                                            We encourage you to review this Privacy Policy periodically for the latest information on our privacy practices.
                                        </div>
                                        <br />
                                    </div>
                                    <br />
                                    <h5>
                                        9. Contact Us
                                    </h5>
                                    <div style={{ marginLeft: '25px' }}>
                                        <h6>
                                            9.1. Questions, Concerns or Grievances:
                                        </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            If you have any questions, concerns or grievances about this Privacy Policy or our data practices, please contact our authorized officer at 
                                             <a href="mailto:bhavya@shopkya.com"> bhavya@shopkya.com</a>. We are committed to addressing your inquiries and resolving any issues in
                                            a timely and transparent manner.<br /><br />

                                            Additionally, please be aware that the App stores your data using the cloud platform provided by
                                            Amazon Web Services, Inc. This means that your data may be stored on servers located outside of India.<br /><br />

                                            Amazon Web Services implements security measures to safeguard against the loss, misuse, and alteration
                                            of information. If you have any questions or concerns regarding the security measures
                                            implemented by Amazon Web Services, you may contact their data protection, privacy, or legal teams.
                                            Their contact information can be found in their privacy policy. <br /><br />

                                            By using the App, you acknowledge that you have read and understood this Privacy Policy and agree
                                            to the collection, storage, use, and disclosure of your information as described herein.
                                        </div>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default PrivacyText;















