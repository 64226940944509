import React from "react";

class TermsText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */
    }

    render() {
        return (
            <React.Fragment>
                <section className="ptb-100 gray-light-bg pt-100 mt-100">
                    <div className="container ">
                        <div className="row justify-content-start">
                            <div className="col-md-12 col-lg-8">
                                <div className="section-heading text-start">
                                    <h2 style={{marginBottom:'0px'}}>Terms And Conditions</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <p className="text-right">Effective Date:  April 1, 2024</p>
                                    <p>
                                        Welcome to ‘ShopKya’ (“ShopKya” or “App”), provided by Sabh Nine Technologies (“we”, “us”, or “our”). By accessing or using this App, you agree to be bound by these detailed Terms of Use (“Terms”). In these Terms, we will refer to users as “users”, “you”, or “your”.
                                        <br /><br />
                                        Please carefully review these Terms before using the App. If you do not agree to these Terms, you are not permitted to use the products and/ or services offered on the App, and we kindly request you to uninstall the App. By accessing or
                                        using the App, you are entering into a contractual agreement with ShopKya, and you indicate your acceptance of these Terms and other ShopKya policies (including, but not limited to, the ShopKya’s Privacy Policy) as available on the App and updated from time to time.
                                        <br /><br />
                                        These Terms take effect on the date of your use of the App, establishing a legally binding arrangement to adhere to them. Moreover, by providing your personal information, you consent to ShopKya accessing your personal data for
                                        the purpose of providing services. Additionally, you confirm your interest in availing services through the App in accordance with these Terms. If you do not agree to these Terms, please do not use the App.
                                    </p>
                                    <h5>
                                        1. Use of the App
                                    </h5>
                                    <div style={{ marginLeft: '25px' }}>
                                        <h6>1.1. License:  </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            We grant you a limited, non-exclusive, non-transferable, and revocable license to access and use the App for your personal, non-commercial use only.
                                        </div>
                                        <br />
                                        <h6>1.2. Purpose of the App:   </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            To access the features of the App, you may need to create an account and register yourself. During registration, you must provide accurate, current, and complete information, and maintain its accuracy over time.
                                            <br /><br />
                                            You are responsible for safeguarding your account credentials and for all activities that occur under your account. You are responsible to promptly notify us of any unauthorized account access or security breaches.
                                            <br /><br />
                                            The App is a versatile platform offering users two primary functionalities:
                                            <br /><br />
                                            <h6 style={{ fontSize: '0.780rem' }}>  (i)  Rewards Services:</h6>
                                            <div style={{ marginLeft: '15px' }}>
                                                <p>
                                                    The App serves as a gateway for users to participate in various rewards programs provided by ShopKya.
                                                    You shall be required to scan/ capture and upload a copy of your shopping receipt, bill, invoice,
                                                    memo, challan (“Receipts”). By uploading the Receipts on the App, users can earn points, coins,
                                                    discounts, coupons, or other incentives (“Incentives”). Upon uploading of such Receipts, the same
                                                    shall be processed further by us and upon successfully verifying the same, you shall be rewarded
                                                    with certain Incentives. These Incentives may be redeemed for cashback offered by us through the
                                                    available payment channel and/ or for promotional coupons, discount vouchers,
                                                    offers, etc. as available from time to time and the same shall solely be at our discretion.
                                                    <br /><br />
                                                    A user shall not, under any circumstances upload any Receipts that:
                                                </p>
                                                <p>

                                                    (a) belongs to another person and to which you do not have any right;
                                                    <br /> (b) contains any false information, is fabricated, duplicated, edited, deceives or misleads the ShopKya with respect to any information stated therein;
                                                    <br /> (c) contains software viruses or any other computer codes, files or programs that is designed to interrupt, destroy or limit the functionality of any computer resource; or
                                                    <br /> (d) is in violation of any law for the time being in force.
                                                </p>
                                                <p>
                                                    In the event any of the above incidents are noticed, ShopKya reserves the right to suspend the respective user(s) account at their own discretion.
                                                </p>
                                            </div>
                                            <br />
                                            <h6 style={{ fontSize: '0.780rem' }}>  (ii) Grocery Shopping: </h6>
                                            <div style={{ marginLeft: '15px' }}>
                                                In addition to rewards services, the App acts as a platform for facilitating grocery shopping. Users can browse through an extensive selection of products from nearby grocery stores partnered with us and place the order on the App.
                                                After placing the order, the respective grocery store may accept, reject or modify the order placed. Upon acceptance and/ or modification of the order, we will collect payment on behalf of the grocery store and upon successful payment,
                                                the order shall be processed further.<br /><br />

                                                The App streamlines the shopping process, offering users the convenience of accessing groceries from the comfort of their homes, along with earning rewards.
                                            </div>
                                        </div>
                                        <br />
                                        <h6>1.3. Usage Restrictions:    </h6>
                                        <div style={{ marginLeft: '25px' }}>

                                            You agree not to:<br />

                                            <br /> (i) Utilize any of our content, trademarks, technological platform, underlying software, or any element of them in any way that is modified, altered or otherwise derivative;
                                            <br /> (ii) Utilize the App for any unlawful purpose or in violation of applicable laws or regulations;

                                            <br /> (iii) Reverse engineer, alter, duplicate, distribute, transmit, perform, display, reproduce, publish, license, make a derivative work of, transfer, sell, or otherwise change any information or software obtained from the App;

                                            <br />  (iv) Infringe upon or violate the rights of ShopKhya, its users or third parties;

                                            <br /> (v) Disrupt or interfere with the operation of the App or its associated servers or networks;

                                            <br /> (vi) Attempt unauthorized access to any portion of the App or related systems;

                                            <br /> (vii) Circumvent or seek to circumvent any security measures or other features meant to protect the security of the App and the users' security;

                                            <br /> (viii) Use the Website other than for its intended purposes (including for any malicious, threatening, unethical, harassing, fraudulent or illegal activity) or otherwise in violation of these Terms;

                                            <br />(ix) Transmit viruses, malware, or any harmful code through the App;

                                            <br /> (x) Employ automated devices or processes to access, retrieve, or scrape content from the App; or

                                            <br />(xi) Violate any applicable laws or regulations for the time being in force in or outside India.
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>2.	Eligibility to use </h5>
                                    <div style={{ marginLeft: '25px' }}>
                                        <p> 2.1. The App's goods and services are exclusively intended for individuals who are legally eligible to enter into contracts. This means that minors, individuals under the age of 18 years, undischarged insolvents, or anyone deemed incompetent to enter into a contract under the Indian Contract Act, 1872,
                                            are not eligible to access or utilize the goods and services provided by the App, unless specified otherwise herein. By using the App, you confirm and warrant that: (i) you are of legal age to enter into a binding contract; (ii) you are not prohibited by any applicable laws from receiving goods and services through the App;
                                            and (iii) you possess the legal competence to enter into contractual agreements.
                                        </p>
                                        <p>
                                            2.2. If you are under the age of 18 years, you shall use and access the App only with the express
                                            consent of a parent or guardian and under their supervision.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>3.	Account Registration </h5>
                                    <div style={{ marginLeft: '25px' }}>
                                        3.1.
                                        To access the offerings available on the App, you must register and create an account by providing the necessary details as outlined in the Privacy Policy ("Account").
                                        <br /><br />
                                        3.2.You are solely accountable for the accuracy of the information you provide to us. It is your
                                        responsibility to ensure that all information, including your Account details, is complete, accurate,
                                        and current. Should any changes occur in your Account information or if any information is discovered
                                        to be incomplete or inaccurate, you must promptly update your Account information on the App or
                                        request ShopKya to revise or update the information. Should the information provided by you is found
                                        to be false, inaccurate, unauthorized, outdated, or incomplete (or subsequently becomes so),
                                        ShopKya reserves the right to decline any and all services. Each user must create only one account.
                                        Creation of multiple accounts shall be a violation of Terms and ShopKya reserves the right to suspend
                                        and/ or block and/ or delete such accounts. ShopKya may refuse your access to the App at any time
                                        without prior notice if there are reasonable grounds to suspect that the information provided by you
                                        is false, inaccurate, outdated, or incomplete,
                                        or if you fail to comply with the Terms outlined herein and the applicable laws.
                                        <br /><br />
                                        3.3. You are responsible for maintaining the confidentiality of your Account information and for all activities conducted under your Account. You agree to promptly notify ShopKya of any unauthorized access to your Account. Failure to maintain the security and confidentiality
                                        of your Account information may result in liability for damages incurred due to unauthorized use of your Account.
                                        <br /><br />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>4.	Purchase and Delivery of Goods </h5>
                                    <div style={{ marginLeft: '25px' }}>

                                        4.1. The App serves as a platform connecting consumers with grocery stores for the purchase and delivery of goods. We act solely as an intermediary,
                                        facilitating transactions between users and grocery stores. Therefore, we do not manufacture, store, or inspect the goods listed on the App.
                                        <br /><br />
                                        4.2. As such, we do not assume any responsibility for the quality, safety, or legality of the goods offered by the grocery stores. Any disputes regarding the quality,
                                        condition, or suitability of the goods purchased through the App must be resolved directly between the consumer and the respective grocery store.
                                        <br /><br />
                                        4.3. We expressly disclaim all warranties, express or implied, regarding the goods and services provided by the grocery stores, including but not
                                        limited to warranties of merchantability, fitness for a particular purpose, and non-infringement. We shall not be liable for any damages, losses, or liabilities arising from the purchase or use of goods obtained through the App, including but not limited to damages for personal injury, property damage, or financial loss.
                                        <br /><br />
                                        4.4. Users are encouraged to exercise caution and diligence when purchasing goods through the App and to review the terms and conditions and
                                        return policies of the respective grocery stores. By using the App, you acknowledge and agree to hold us harmless from any claims, damages, or liabilities arising out of or related to the goods and services provided by the grocery stores.

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>5.	Payment </h5>
                                    <div style={{ marginLeft: '25px' }}>

                                        5.1. You acknowledge and agree that ShopKya may use third-party vendors and service providers, including payment gateways,
                                        to process payments and handle payment card information, subject to the Privacy Policy and applicable law.
                                        <br /><br />
                                        5.2. To purchase the goods, you undertake to provide valid payment details for processing the payment.
                                        By doing so, you affirm that you are legally authorized to provide and use such payment details and that this action complies with applicable terms and conditions and laws. You can manage your payment details through the App.
                                        <br /><br />
                                        5.3. ShopKya is not liable for payments made using your payment details, except as required by applicable law.
                                        This includes instances where transactions fail due to insufficient funds, incorrect payment details, expired payment cards,
                                        or circumstances beyond ShopKya's control.
                                        <br /><br />
                                        5.4. ShopKya is not responsible for unauthorized transactions made on the App using your payment details and is not obligated to refund any
                                        money in such cases.
                                        <br /><br />
                                        5.5. The payment facility provided by ShopKya is neither a banking nor a financial service but is merely a
                                        facility providing an electronic, automated, online payment system, and receiving payment on delivery for the transactions on the Platform using the existing authorized banking infrastructure and card payment gateway networks, as may be applicable. Your issuer may impose fees for certain payment methods, such as transaction fees, related to processing your transaction.

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>6.	Intellectual Property Rights </h5>
                                    <div style={{ marginLeft: '25px' }}>

                                        <h6> 6.1. Ownership:</h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            The App, including all content, features, and functionality, is owned by ShopKya or its licensors and is protected by intellectual property laws, including but not limited to copyright, trademark, patent, trade secret, logos and other proprietary rights. You acknowledge that the App and its contents are valuable assets of ShopKya and agree not to reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the materials on the App, except as expressly permitted herein.

                                            Nothing contained on the App should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any intellectual property on the App without the prior written permission of Shop
                                            Kya or such third-party owner.
                                        </div>
                                        <br />
                                        <h6>  6.2. License Grant:</h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            Subject to your compliance with these Terms, ShopKya grants you a limited, non-exclusive, non-transferable, revocable license to access and use the App for your personal, non-commercial use. This license does not include any resale or commercial use of the App or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of the App or its contents; any downloading or copying of account information for the benefit of another merchant; or any use of data mining, robots, or similar data gathering and extraction tools. All rights not expressly granted to you herein are reserved by ShopKya.
                                        </div><br />
                                        <h6> 6.3. User Content: </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            By submitting or posting any content on the App, including but not limited to Receipts, reviews, comments, feedback, suggestions, ideas, or other submissions (collectively, "User Content"), you grant ShopKya a non-exclusive, royalty-free, perpetual, transferable, irrevocable, and fully sub-licensable right to use, reproduce, run, modify, adapt, publish, translate, create derivative works from, distribute, and display such User Content throughout the world in any media. You represent and warrant that you own or otherwise control all of the rights to the User Content that you post; that the User Content is accurate and that you have all the necessary rights to grant it; that use of the User Content you supply does not violate these Terms and will not cause injury to any person or entity; and that you will indemnify ShopKya for all claims resulting from User Content you supply.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>7.	Third-Party Links and Content </h5>
                                    <div style={{ marginLeft: '25px' }}>

                                        <h6> 7.1. Third-Party Sites Exploration: </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            The App serves as a gateway to a myriad of online resources, including third-party websites or services. These may encompass a diverse range of offerings such as informational content, products, or services beyond our direct control. While we facilitate access to these third-party sites, it's imperative to note that we do not wield control over their content or privacy practices. Consequently, users are encouraged to exercise discretion and review the
                                            terms of use and privacy policies of any third-party sites visited through the App.
                                        </div>
                                        <br />
                                        <h6>  7.2. Third-Party Content Dissection: </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            Within the App, you may encounter a plethora of perspectives, opinions, advice, or informational content provided by third parties. These insights are the sole responsibility of their respective authors or distributors. We neither endorse nor validate the accuracy, reliability, or suitability of such content. Users are advised to approach third-party content with critical discernment and should rely on their own judgment when engaging with such materials.
                                        </div><br />
                                        {/* <h6> 7.3. Third-Party Content Dissection:  </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            Within the App, you may encounter a plethora of perspectives, opinions, advice, or informational content provided by third parties. These insights are the sole responsibility of their respective authors or distributors. We neither endorse nor validate the accuracy, reliability, or suitability of such content. Users are advised to approach third-party content with critical discernment and should rely on their own judgment when engaging with such materials.
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>8.	Return, Refund, and Cancellation Policy: </h5>
                                    <div style={{ marginLeft: '25px' }}>

                                        <h6> 8.1. Returns and Refunds Procedure: </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                        All return and/ or refund requests shall only be entertained and contained valid if made within 48 (forty eight) hours of delivery of goods. The process of returning purchased items and seeking refunds via the App is subject to the individual policies stipulated by the 
                                        participating merchants or vendors. As an intermediary platform, we do not directly administer returns or refunds.  <br /><br />

                                            No replacements, refunds, or other resolutions will be initiated without obtaining permission from the grocery store(s). Prior verification from the grocery store is necessary before proceeding with any refund process. Any complaints regarding an order, including issues like delivery of incorrect items, missing items, damaged or poor quality of the product, require accompanying proof for resolution. Without sufficient evidence, ShopKya will not consider the complaint valid, and no refunds or replacements will be provided. <br /><br />

                                            It is incumbent upon users to acquaint themselves with the specific return and refund policies delineated by the respective merchant or vendor. These policies encompass eligibility criteria, procedural steps, evidential support and the stipulated timeframe within which returns and refunds must be initiated.
                                        </div>
                                        <br />
                                        <h6>  8.2. Cancellation: </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            In certain scenarios, users may find it necessary to cancel an order placed through the App. The feasibility and parameters surrounding order cancellations are contingent upon the policies established by the relevant merchant or vendor. Users are encouraged to consult the cancellation policy delineated by the specific merchant or vendor for comprehensive insights into the cancellation process, including any associated cancellation fees or limitations.
                                            <br /><br />
                                            Cancellations arising from factors not attributable to ShopKya, such as providing incorrect phone numbers or delivery addresses, or failing to respond when contacted by our team or the delivery team, will not be eligible for refunds.
                                            <br /><br />
                                            There may be instances where ShopKya or the grocery store is unable to fulfill or must cancel an order due to technical glitches, item unavailability, or other reasons. In such cases, no cancellation fees will be imposed by ShopKya. If payment has already been processed for a canceled order, you are entitled to a refund of the order value or a portion thereof, which will be credited back to you.
                                        </div><br />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>9.	Disclaimer of Warranties</h5>
                                    <div style={{ marginLeft: '25px' }}>

                                        <h6> 9.1. No Warranties:  </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            The App is provided to you on an "as is" and "as available" basis, without any warranties or representations of any kind, whether express, implied, or statutory. We expressly disclaim all warranties, including but not limited to warranties of merchantability, fitness for a particular purpose, non-infringement, and accuracy of information. We do not warrant that the App will meet your requirements, be uninterrupted, timely, secure, or error-free, or that any defects or errors will be corrected.
                                            You acknowledge and agree that your use of the App is at your own risk and discretion.
                                        </div>
                                        <br />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>10.	Limitation of Liability</h5>
                                    <div style={{ marginLeft: '25px' }}>

                                        <h6> 10.1. No Liability:  </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            In no event shall we or our affiliates, directors, officers, employees, agents, or licensors be liable to you or any third party for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in any way connected with your use of or inability to use the App, including but not limited to damages for lost profits, loss of data, loss of goodwill, or other intangible losses, regardless of whether such damages arise under contract, tort, negligence, strict liability,
                                            or any other legal theory, even if we have been advised of the possibility of such damages.
                                        </div>
                                        <br />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>11.	Indemnification</h5>
                                    <div style={{ marginLeft: '25px' }}>

                                        <h6> 11.1. Indemnity:  </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            You agree to indemnify, defend, and hold us and our affiliates, directors, officers, employees, agents, and licensors harmless from and against any claims, liabilities, damages, losses, and expenses, including but not limited to reasonable attorneys' fees and costs, arising out of or in any way related to your use of the App, your violation of these Terms, or your violation of any rights of another party.
                                        </div>
                                        <br />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>12.	Governing Law and Dispute Resolution</h5>
                                    <div style={{ marginLeft: '25px' }}>

                                        <h6> 12.1. Governing Law: </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            These Terms and any disputes arising out of or related to your use of the App shall be
                                            governed by and construed in accordance with the laws of India without reference to conflict of laws principles and the jurisdiction of the courts or any quasi-judicial forum shall exclusively be that of Mumbai, Maharashtra.
                                        </div>
                                        <br />
                                        <h6> 12.2. Grievance Redressal Mechanism: </h6>
                                        <div style={{ marginLeft: '25px' }}>

                                            12.2.1. If you encounter any issues, you can initiate real-time resolution by reaching out to us through the chat support feature on the App. Alternatively, you have the option to contact us via email at <a href="mailto:bhavya@shopkya.com">bhavya@shopkya.com</a>.
                                            <br /><br />
                                            12.2.2. Our team will strive to acknowledge your grievances, complaints, or concerns pertaining to the Platform, content, or supplies within 48 hours of receiving it, and will make every effort to address them promptly, ensuring resolution no later than 30 days from the date of such receipt. By lodging a complaint or grievance, you agree to cooperate fully with our team and provide any and all reasonable information requested.

                                        </div>
                                        <br />
                                        <h6> 12.3. Dispute Resolution:  </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            In the event of any subsisting dispute, claim, or controversy arising out of or relating to these Terms or the breach, termination, enforcement, interpretation, or validity thereof,
                                            including the determination of the scope or applicability of this agreement to arbitrate, shall be determined by arbitration in Mumbai, pursuant to the rules and regulations
                                            framed under the Arbitration and Conciliation Act, 1996 in effect at the time of filing of the demand for arbitration.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>13.	Changes to Terms of Use</h5>
                                    <div style={{ marginLeft: '25px' }}>
                                        <h6> 13.1. Updates:  </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            We reserve the right to modify or revise these Terms at any time without prior notice. Any changes will be effective immediately upon posting the updated Terms on the App. Your continued use of
                                            the App following the posting of any changes constitutes acceptance of those changes.
                                        </div>
                                        <br />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>14. Contact Us</h5>
                                    <div style={{ marginLeft: '25px' }}>
                                        <h6> 14.1. Questions or Concerns:   </h6>
                                        <div style={{ marginLeft: '25px' }}>
                                            If you have any questions, concerns, or feedback regarding these Terms of Use or the App, please do not hesitate to contact
                                            us at  <a href="mailto:bhavya@shopkya.com">bhavya@shopkya.com</a>. We are here to assist you and address any inquiries you may have.
                                        </div>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default TermsText;















