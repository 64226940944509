import React from "react";
import { connect } from "react-redux";
import _data from "../../data";

class PromoSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            promo: {}
        };
    }

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */

        this.setState({
            promo: _data.promo
        });
    }

    render() {
        return (
            <React.Fragment>
                <section id="about" className="promo-section mt-100 ptb-100 gray-light-bg">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-9">
                                <div className="section-heading text-center">
                                    <h2 className="mb-5">About ShopKya</h2>
                                    <p style={{ fontSize: '18px', textAlign: 'left', color: '#141615' }} className="text-[18px] text-[#141615]">
                                        At ShopKya, we're rewriting the rules of loyalty with a simple idea – turning your receipts into rewards. With us, it's not about buying through us; it's about turning your receipts into rewards from your go-to brands. Forget the fuss of traditional loyalty programs - with ShopKya, it's as easy as snapping a receipt and your rewards are in your account. Our app brings you personalized deals tailored to your preference. We've made the whole process super easy, from scanning receipts to enjoying your well-deserved rewards.<br /><br />
                                        Stay in the loop with the latest deals and product launches, and earn and redeem rewards wherever you love to shop. Join us at ShopKya, where your receipts bring you more than just purchases – they bring you cash backs and rewards!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default connect(state => ({
    state
}))(PromoSection);
