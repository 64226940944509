import React from "react";

class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
       const LengthbyDefult = window.innerWidth>996 &&  window.innerWidth<1200
        return (
            <React.Fragment>
                <section id="loyalty" className="about-us ptb-100 position-relative gray-light-bg">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9 col-lg-10">
                                <div className="section-heading text-center mb-5">
                                    <h2 style={{ fontSize: '1.8rem' }}>How does ShopKya's loyalty program boost repeat sales? </h2>
                                    <p style={{ fontSize: '18px', color: '#141615' }}> It's simple, powerful, and fast</p>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 col-lg-3">
                                    <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4 shadow">
                                        <div className="card-body">
                                            <div className="pb-2">
                                                <span className="fa fa-link icon-size-md color-secondary"></span>
                                            </div>
                                            <div style={{height: LengthbyDefult ?'180px' :'160px'}} className="pt-2 pb-3">
                                                <h5>Connect</h5>
                                                <p className="mb-0">Foster lasting connections through personalized loyalty programs.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3">
                                    <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4 shadow">
                                        <div className="card-body">
                                            <div className="pb-2">
                                                <span className="fa fa-volume-up icon-size-md color-secondary"></span>
                                            </div>
                                            <div style={{height: LengthbyDefult ?'185px' :'160px'}} className="pt-2 pb-3">
                                                <h5>Amplify</h5>
                                                <p className="mb-0">Boost brand presence and impact in the market.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3">
                                    <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4 shadow">
                                        <div className="card-body">
                                            <div className="pb-2">
                                                <span className="fas fa-chart-line icon-size-md color-secondary"></span>
                                            </div>
                                            <div style={{height: LengthbyDefult ?'185px' :'160px'}} className="pt-2 pb-3">
                                                <h5>Engage</h5>
                                                <p className="mb-0">Connect on a deeper level, building trust and long-term relationships.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3">
                                    <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4 shadow">
                                        <div className="card-body">
                                            <div className="pb-2">
                                                <span className="fa fa-shapes icon-size-md color-secondary"></span>
                                            </div>
                                            <div style={{height: LengthbyDefult ?'185px' :'160px'}} className="pt-2 pb-3">
                                                <h5>Tailor</h5>
                                                <p className="mb-0">Provide deals tailored to your customers' preferences.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3">
                                    <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4 shadow">
                                        <div className="card-body">
                                            <div className="pb-2">
                                                <span className=" fas fa-magnet icon-size-md color-secondary"></span>
                                            </div>
                                            <div style={{height: LengthbyDefult ?'185px' :'160px'}} className="pt-2 pb-3">
                                                <h5>Attract</h5>
                                                <p className="mb-0">Captivate potential customers, expanding your consumer base.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3">
                                    <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4 shadow">
                                        <div className="card-body">
                                            <div className="pb-2">
                                                <span className="fas fa-lightbulb icon-size-md color-secondary"></span>
                                            </div>
                                            <div style={{height: LengthbyDefult ?'185px' :'160px'}} className="pt-2 pb-3">
                                                <h5>Innovate</h5>
                                                <p className="mb-0">Safely launch new products with comprehensive surveys and testing.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3">
                                    <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4 shadow">
                                        <div className="card-body">
                                            <div className="pb-2">
                                                <span className="fa fa-cubes icon-size-md color-secondary"></span>
                                            </div>
                                            <div style={{height: LengthbyDefult ?'185px' :'160px'}} className="pt-2 pb-3">
                                                <h5>Customize</h5>
                                                <p className="mb-0">Tailor points, vouchers, or rewards to align with business goals.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3">
                                    <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4 shadow">
                                        <div className="card-body">
                                            <div className="pb-2">
                                                <span className="fas fa-expand icon-size-md color-secondary"></span>
                                            </div>
                                            <div style={{height: LengthbyDefult ?'185px' :'160px'}} className="pt-2 pb-3">
                                                <h5>Maximize</h5>
                                                <p className="mb-0">Gain valuable insights into consumer behaviour and preferences for enhanced engagement.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row align-items-center justify-content-lg-between justify-content-md-center">
                            <div className="col-md-12 col-lg-6">
                                <div className="about-content-left section-heading">
                                    <ul className="check-list-wrap ">
                                        <li style={{ color: "#141615" }} ><strong>Create loyalty</strong> – Foster lasting connections with your customers through personalized loyalty programs. </li>
                                        <li style={{ color: "#141615" }}><strong>Expand visibility and influence</strong> – Boost your brand's presence and impact in the market. </li>
                                        <li style={{ color: "#141615" }}><strong>Engage with consumers </strong> – Connect with your audience on a deeper level, building trust and long-term relationships.</li>
                                        <li style={{ color: "#141615" }}><strong>Deliver relevant deals</strong> – Provide tailor-made offers that resonate with your customers' preferences.</li>
                                        <li style={{ color: "#141615" }}><strong>Influence new customers</strong> – Attract and captivate potential customers, expanding your consumer base.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6">
                                <div className="about-content-left section-heading">
                                    <ul className="check-list-wrap pt-4">
                                        <li style={{ color: "#141615" }}><strong>Introduce new products with comprehensive surveys and testing</strong> – Safely innovate and launch new products with thorough surveys and testing, ensuring success in the market.</li>
                                        <li style={{ color: "#141615" }}><strong>Customization at your fingertips</strong>– Tailor loyalty points, vouchers, or rewards to align with your business goals. </li>
                                        <li style={{ color: "#141615" }}><strong>Insights that matter</strong> – Maximize engagement with valuable insights into consumer behaviour and preferences.</li>
                                        <li style={{ color: "#141615" }}><strong>Omnichannel experience</strong> – Earn and redeem loyalty points effortlessly - whether it's through the app or in-store interactions.</li>
                                        <li style={{ color: "#141615" }}><strong>Focus on ROI</strong> – Our loyalty platform is created to ensure the highest ROI and increased sales for your business.</li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default About;
